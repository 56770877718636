import app from "./icasf_app";

export default class Component {
  static eventNamespace = null;

  constructor(props = {}) {
    this.props = props;
    this.setUpElements();
    this.setUpState();
    this.setUpEvents();
  }

  setUpElements() {
    this.elements = {};
  }

  setUpState() {
    this.state = this.defaultState;
  }

  setUpEvents() {
    this.events = {};
  }

  on(event, handler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler);

    return this.off.bind(this, event, handler);
  }

  off(event, handler) {
    this.events[event] = this.events[event].filter((e) => e !== handler);
  }

  emit(event, {...args} = {}) {
    const formattedArgs = Object.assign({}, this.defaultEventData, args);
    if (this.events[event]?.length) {
      this.events[event].forEach((cb) => cb.call(this, formattedArgs));
    }

    const key = this.constructor.eventNamespace
      ? `${this.constructor.eventNamespace}:${event}`
      : event;
    app.emit(key, formattedArgs);
  }

  update(update) {
    const previousState = Object.assign({}, this.state);
    Object.assign(this.state, update);
    return this.render(update, previousState);
  }

  render(_update, _previousState) {
    return true;
  }

  destroy() {
    this.unlistenDestroy();
  }

  get defaultEventData() {
    return {};
  }

  get defaultState() {
    return {};
  }
}
