import app from "../icasf_app";
import focusLock from "../utilities/focus-lock";
import * as util from "../utilities";
import Component from "../component";

export class Modal extends Component {
  static eventNamespace = "modal"

  /* Public methods */
  open(content = "", location = false) {
    const update = {
      content,
      open: true,
      focus: this.firstFocusableElement,
      focusReleaseTarget: document.activeElement,
      focusLock: true,
    };

    // If a location is supplied, we update the current URL and add an entry to history.
    // If the modal is currently closed, we want to keep a record of the current page,
    // so that when the user clicks the close button, we can return here
    if (location) {
      update.location = location;
      if (!this.state.open) {
        update.pageToRetunTo = window.location.href;
      }
    }
    this.update(update);
    this.emit("open");
  }

  close(returnToPreviousPage = true) {
    const update = {};
    update.open = false;
    update.content = "";
    update.focus = this.state.focusReleaseTarget;
    update.focusReleaseTarget = null;
    update.focusLock = false;

    // If we are just closing the modal, we want to return to the previous page
    // If we're closing the modal because we are visiting a new page, we don't want to do that.
    if (this.state.pageToRetunTo && returnToPreviousPage) {
      update.location = this.state.pageToRetunTo;
    }

    update.pageToRetunTo = null;
    this.update(update);

    this.emit("close");
  }

  load(url, addHistoryEntry = true) {
    const formattedUrl = util.editSearchParams(url, (params) => {
      params.set("remote", "modal");
    });
    const pageToRetunTo = addHistoryEntry && window.location.href;

    util.fetchPageMarkup(formattedUrl).then((html) => {
      this.open(html, addHistoryEntry && url);
      this.emit("load", {
        pageToRetunTo,
        location: url,
        target: this.elements.modal,
      });
    });
  }

  /* Initialzer methods */
  setUpElements() {
    super.setUpElements();

    this.elements.modal = document.getElementById("modal");
    this.elements.content = document.getElementById("modal-content");
    this.elements.closeButton = document.getElementById("modal-close");
  }

  setUpEvents() {
    app.addEventListener("click", {
      name: "modal-closer",
      handler: (e) => {
        if (!e.target.closest(".js-modal__close")) {
          return;
        }
        this.close();
      },
    });

    app.addEventListener("click", {
      name: "modal-opener",
      handler: (e) => {
        if (util.isEconomyEditMode()) {
          return;
        }

        const link = e.target.closest("[data-modal-link]");
        if (!link) {
          return;
        }

        e.preventDefault();
        this.load(link.getAttribute("data-modal-link"));
      },
    });

    app.addEventListener("click", {
      name: "modal-quiet-opener",
      handler: (e) => {
        if (util.isEconomyEditMode()) {
          return;
        }

        const link = e.target.closest("[data-modal-link-without-history]");
        if (!link) {
          return;
        }

        e.preventDefault();
        this.load(link.getAttribute("data-modal-link-without-history"), false);
      },
    });

    app.addEventListener("popstate", {
      name: "modal-history",
      handler: (e) => {
        // If the user click "back" or "forward", and we've marked that history entry as a page that was opened in a modal,
        // then we should open the modal like
        if (e.state && e.state.isModalPage === true) {
          this.open(e.state.modalContent, false);
          this.emit("load", {
            target: this.modal,
          });
        }
        // If the "back" event is to a page that *wasn't* opened in a modal, but the modal is open,
        // then we should close the modal!
        else if (this.state.open) {
          this.close(false);
        }
      },
    });

    // Escape key closes the modal
    app.addEventListener("keydown", {
      name: "modal-escape-closer",
      handler: (e) => {
        if (!this.state.open) {
          return;
        }

        if (e.keyCode === 27) {
          this.close();
        }
      },
    });

    // Clicking outside the modal closes it
    app.addEventListener("click", {
      name: "modal-backdrop-click-closer",
      handler: (e) => {
        if (!this.state.open) {
          return;
        }

        if (
          this.elements.modal.contains(e.target) &&
          !this.elements.content.contains(e.target) &&
          !e.target.matches(".js-modal-controls *")
        ) {
          this.close();
        }
      },
    });

    /* 
      If a link is clicked inside a modal page, the modal should close
      and the resultant page is loaded as a normal page.

      Using `before-cache` is important here because how we create History
      entries while working around Turbolinks. Basically, when we open the
      modal, we add a History entry with the modal content stored as in its
      snapshot state. We do that so that we can handle `popstate` events and
      restore the modal's content. Turbolinks, however, still thinks we're on
      the same page. Therefore, when Turbolinks caches the page, we want the
      captured HTML to *not* have the modal, since the page didn't have the
      modal when it was first loaded.
    */
    app.addEventListener("turbolinks:before-cache", () => {
      if (!this.state.open) {
        return;
      }

      this.close(false);
    });
  }

  /* State management methods */
  get defaultState() {
    return {
      open: false,
      content: null,
      focus: null,
      focusReleaseTarget: null,
      focusLock: false,
      location: null,
    };
  }

  get firstFocusableElement() {
    this.elements.closeButton ||= document.getElementById("modal-close");
    return this.elements.closeButton;
  }

  /* Rendering methods */
  render(update, previousState) {
    super.render(update, previousState);

    if (update.hasOwnProperty("content")) {
      this.renderModalBody(update.content);
    }

    if (update.hasOwnProperty("open")) {
      this.renderOpenState(update.open);
    }

    if (update.hasOwnProperty("location")) {
      this.renderLocation(update.location);
    }

    if (update.hasOwnProperty("focusLock")) {
      if (this.state.focusLock) {
        this.unlockFocus = focusLock.lock({
          selectors: [".js-modal *"],
          loopTarget: this.firstFocusableElement,
        });
      } else if (this.unlockFocus) {
        this.unlockFocus();
      }
    }

    return true;
  }

  renderOpenState(isOpen) {
    if (isOpen) {
      this.elements.modal.style.setProperty('display', 'flex')
      document.documentElement.style.setProperty('overflow', 'hidden');
    } else {
      this.elements.modal.style.setProperty('display', 'none')
      document.documentElement.style.removeProperty('overflow');
    }
  }

  renderModalBody(html) {
    this.elements.content.innerHTML = html;
    this.elements.content.scrollTo(0, 0);
  }

  renderLocation(href) {
    const snapshot = {};
    snapshot.isModalPage = Boolean(this.state.content);
    snapshot.modalContent = this.state.content;
    snapshot.pageToRetunTo = this.state.pageToRetunTo;
    history.pushState(snapshot, null, href);
  }

  /*
    Misc
  */
  emit(event, args = {}) {
    app.emit(`modal:${event}`, args);
  }
}

export const modal = {
  current: null,
  init: () => {
    document.addEventListener('DOMContentLoaded', () => {
      if (util.isEconomyEditMode()) {
        return;
      }

      modal.current = new Modal();
    });
  }
}

window.modal = modal;
