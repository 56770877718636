/* global $ */
import app from "../icasf_app";
import { isEconomyEditMode } from "../utilities";

const resetAllAccordionAffectedModules = () => {
  [...document.querySelectorAll(
    ".starts-accordion, .ends-accordion, .accordion-content"
  )].forEach((el) => {
    el.classList.remove(
      "starts-accordion",
      "ends-accordion",
      "accordion-content"
    )
  })
};

const updateAccordionAffectedModules = (startModule) => {
  const parent = $(startModule).closest(".edit-interface.mm")[0];
  parent.classList.add("starts-accordion");
  let el = parent.nextElementSibling;

  while (el) {
    const endsAccordion = Boolean(el.querySelector(".js-accordion-end-banner"));
    if (endsAccordion) {
      el.classList.add("ends-accordion");
      break;
    } else {
      el.classList.add("accordion-content");
      el = el.nextElementSibling;
    }
  }
};

const updateAccordionHints = () => {
  resetAllAccordionAffectedModules();
  [...document.querySelectorAll(".js-accordion-module")].forEach(updateAccordionAffectedModules)
};

export default () => {
  app.addEventListener("turbolinks:load", () => {
    if (!isEconomyEditMode()) {
      return;
    }

    updateAccordionHints();
    $(document).on("economy:magic_module_updated", updateAccordionHints);
    $(document).on("economy:magic_module_order_updated", updateAccordionHints);
    $(document).on("economy:magic_module_removed", updateAccordionHints);
  });
};
