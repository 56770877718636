import app from "../icasf_app";
import Component from "../component";
import { fetchPageMarkup, getLastItem, wait } from "../utilities";
import { animateProperty } from "../utilities/animation";

const OPEN_CLASS_NAME = "main-nav--open";
const SUSPENDED_CLASS_NAME = "main-nav--suspended";

export class MainNav extends Component {
  static eventNamespace = "main-nav";
  get defaultState() {
    return {
      open: false,
      buttonLabel: "Menu",
      index: null,
      item: null,
      elementsToShift: [],
      elementsToUnshift: [],
      suspended: false,
    };
  }

  constructor(props) {
    super(props);
    this.refreshItems();
  }

  setUpElements() {
    super.setUpElements();

    const { root } = this.props;
    this.elements.root = root;
    this.elements.toggler = root.querySelector(".js-main-nav__toggler");
    this.elements.backdrop = root.querySelector(".js-main-nav__backdrop");
    this.elements.body = root.querySelector(".js-main-nav__body");
    this.elements.pageContainer = document.getElementById("page-content");
  }

  refreshItems() {
    const { root } = this.props;

    // Re-query for elements so that the order is correct
    const currentPage = (this.elements.currentPage =
      this.elements.pageContainer.firstElementChild);
    const items = (this.elements.items = [
      ...root.querySelectorAll(".js-main-nav__item"),
    ]);

    // Re-bind event handler so that interaction is correct
    items.forEach((item, index, items) => {
      const link = item.querySelector("a");
      const itemsToCycle = items.slice(0, index);
      const itemsToSurface = items.slice(index);
      const newOrder = [...itemsToSurface, ...itemsToCycle];
      const pageToCycle = document.querySelector("#page-content > div");
      const href = link.href;
      link.onclick = this.handleLinkClick.bind(this, {
        index,
        item,
        link,
        itemsToCycle,
        pageToCycle,
        itemsToSurface,
        newOrder,
        href,
      });
      const elementsToShift = index > 0 ? [...itemsToCycle, currentPage] : [];
      const elementsToUnshift =
        index > 0 ? [...itemsToSurface, item] : [...items, currentPage];
      item.onmouseenter = this.handleItemMouseEnter.bind(this, {
        elementsToShift,
        elementsToUnshift,
      });
    });

    this.elements.body.addEventListener("mouseleave", () => {
      if (this.state.suspended || app.state.breakpoint === "sm" || app.state.breakpoint === "md") {
        return;
      }

      this.update({
        elementsToShift: [],
        elementsToUnshift: [...items, currentPage],
      });
    });

    // Reset state
    const index = 0;
    const item = this.elements.items[index];
    this.update({
      index,
      item,
    });
  }

  setUpEvents() {
    super.setUpEvents();

    this.elements.toggler.onclick = this.toggle.bind(this);
    this.elements.backdrop.onclick = this.close.bind(this);
  }

  /*
  ::::::::::::::::::::
  :: PUBLIC METHODS ::
  ::::::::::::::::::::
  */
  open() {
    // Visually open the menu
    this.update({
      open: true,
      buttonLabel: "Close",
    });

    // Emit the event for public tie-ins
    this.emit("open");
  }

  close() {
    // Reset the visuals
    this.update({
      open: false,
      buttonLabel: "Menu",
    });

    // Emit the event for public tie-ins
    this.emit("close");
  }

  toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  suspend() {
    this.update({
      suspended: true,
    });
  }

  unsuspend() {
    this.update({
      suspended: false,
    });
  }

  destroy() {
    this.close();
    super.destroy();
  }

  /*
  ::::::::::::::::::::::::::::
  :: EVENT HANDLERS METHODS ::
  ::::::::::::::::::::::::::::
  */

  handleItemMouseEnter({ elementsToShift, elementsToUnshift }) {
    if (this.state.suspended || app.state.breakpoint === "sm" || app.state.breakpoint === "md") {
      return;
    }

    this.update({ elementsToShift, elementsToUnshift });
  }

  handleLinkClick(elements, e) {
    // TODO: Remove this once the mobile interaction works
    if (app.state.breakpoint === "sm" || app.state.breakpoint === 'md') {
      if (!this.state.open) {
        this.open();
        e.preventDefault();
      }
      return;
    }

    e.preventDefault();

    this.suspend();

    const { link } = elements;
    const { href } = link;

    // Update the URL right away in case anything goes wrong. If the URL is correct, the user can just reload the page.
    history.pushState({}, null, href);

    // In most cases, we will cycle out "old" nav items and reshuffle them to the back.
    // But if the user clicks the current item (for example, clicking "calendar" when on an Event Page)
    // we just want to crossfade the pages.
    if (Boolean(elements.itemsToCycle.length)) {
      return this.cycleOut(elements)
        .then(() => {
          return this.loadNextPage(href);
        })
        .then((nextPage) => {
          elements.nextPage = nextPage;
          window.scrollTo(0, 0);
          return this.surface(elements);
        })
        .then(() => {
          return this.shuffleBack(elements);
        })
        .then(() => {
          return this.settle(elements);
        })
        .then(() => {
          this.finishPageLoad(elements);
        });
    } else {
      return animateProperty({
        element: elements.pageToCycle,
        property: "opacity",
        value: 0,
      })
        .then(() => {
          return this.loadNextPage(href);
        })
        .then((nextPage) => {
          elements.nextPage = nextPage;
          window.scrollTo(0, 0);
          return animateProperty({
            element: nextPage,
            property: "opacity",
            value: 1,
          });
        })
        .then(() => {
          return this.settle(elements);
        })
        .then(() => {
          this.finishPageLoad(elements);
        })
    }
  }

  /*
  ::::::::::::::::::::
  :: RENDER METHODS ::
  ::::::::::::::::::::
  */
  render(update, previousState) {
    super.render(update, previousState);

    if (update.hasOwnProperty("open")) {
      this.renderOpenState(previousState);
    }

    if (update.hasOwnProperty("buttonLabel")) {
      this.renderButtonLabel();
    }

    if (update.hasOwnProperty("suspended")) {
      this.renderSuspendedState();
    }

    if (
      update.hasOwnProperty("elementsToShift") ||
      update.hasOwnProperty("elementsToUnshift")
    ) {
      this.shiftElements(previousState);
    }
  }

  renderOpenState(previousState) {
    if (previousState.open) {
      this.elements.backdrop.style.removeProperty("display");
      this.elements.items.forEach((item) => {
        item.style.removeProperty("transform");
      });
      document.documentElement.style.removeProperty("overflow");
    } else {
      this.elements.backdrop.style.setProperty("display", "block");
      this.elements.items.forEach((item, index, list) => {
        item.style.setProperty(
          "transform",
          `translateY(${-1.5 * (list.length - index)}em)`
        );
      });
      document.documentElement.style.setProperty("overflow", "hidden");
    }
  }

  renderButtonLabel() {
    this.elements.toggler.textContent = this.state.buttonLabel;
  }

  renderSuspendedState() {
    if (this.state.suspended) {
      this.elements.root.classList.add(SUSPENDED_CLASS_NAME);
    } else {
      this.elements.root.classList.remove(SUSPENDED_CLASS_NAME);
    }
  }

  shiftElements(previousState) {
    // TODO: this isn't the correct value. We should be using REMs
    let translateDirection;
    const translateAmount = 15;
    if (app.state.breakpoint === "sm" || app.state.breakpoint === 'md') {
      translateDirection = "Y";
    } else {
      translateDirection = "X";
    }
    return Promise.all(
      this.state.elementsToShift.map((element) =>
        animateProperty({
          element,
          property: `transform`,
          value: `translate${translateDirection}(${-1 * translateAmount}px)`,
        })
      ),
      this.state.elementsToUnshift.map((element) =>
        animateProperty({
          element,
          property: `transform`,
          value: `translate${translateDirection}(0px)`,
        })
      )
    );
  }

  cycleOut({ itemsToCycle, itemsToSurface, pageToCycle }) {
    const lastItem = (
      getLastItem(itemsToCycle) || this.elements.root
    ).getBoundingClientRect();
    let translateDirection;
    let translateAmount;
    if (app.state.breakpoint === "sm" || app.state.breakpoint === 'md') {
      translateDirection = "Y";
      translateAmount = lastItem.bottom;
    } else {
      translateDirection = "X";
      translateAmount = lastItem.right + 20;
    }
    const animatedElements = [...itemsToCycle, pageToCycle];

    // Before animation, make sure the z-indexes are what we expect
    [
      pageToCycle,
      ...itemsToCycle.slice().reverse(),
      ...itemsToSurface.slice().reverse(),
    ].forEach((element, index) => element.style.setProperty("z-index", index));

    return Promise.all(
      animatedElements.map((item) =>
        animateProperty({
          element: item,
          property: "transform",
          value: `translate${translateDirection}(${-1 * translateAmount}px)`,
        })
      )
    );
  }

  loadNextPage(href) {
    return fetchPageMarkup(href).then((html) => {
      // Render the new page slightly to the right of where it needs to be
      const nextPage = document.createElement("div");
      nextPage.innerHTML = html;
      nextPage.className = "height-100% bg-white";
      nextPage.style.setProperty("position", "absolute");
      nextPage.style.setProperty("top", "0");
      nextPage.style.setProperty("left", "0");
      nextPage.style.setProperty("width", "100%");
      nextPage.style.setProperty("height", "100%");
      nextPage.style.setProperty("z-index", "-1");
      nextPage.style.setProperty("opacity", "0");
      this.elements.pageContainer.appendChild(nextPage);
      nextPage.offsetWidth;

      return nextPage;
    });
  }

  surface({ itemsToSurface, itemsToCycle, nextPage }) {
    // Before animation, make sure the z-indexes are what we expect
    [
      ...itemsToCycle.slice().reverse(),
      nextPage,
      ...itemsToSurface.slice().reverse(),
    ].forEach((element, index) => element.style.setProperty("z-index", index));

    let translateDirection;
    let translateAmount;
    if (app.state.breakpoint === "sm" || app.state.breakpoint === 'md') {
      translateDirection = "Y";
      translateAmount = itemsToSurface[0].offsetTop;
    } else {
      translateDirection = "X";
      translateAmount = itemsToSurface[0].offsetLeft;
    }

    nextPage.style.setProperty(
      "transform",
      `translate${translateDirection}(${translateAmount}px)`
    );

    return Promise.all([
      ...itemsToSurface.map((item) =>
        animateProperty({
          element: item,
          property: "transform",
          value: `translate${translateDirection}(${-1 * translateAmount}px)`,
        })
      ),
      animateProperty({
        element: nextPage,
        property: "transform",
        value: `translate(0, 0)`,
      }),
    ]).then(() => {
      return animateProperty({
        element: nextPage,
        property: "opacity",
        value: 1,
      });
    });
  }

  shuffleBack({ itemsToCycle, nextPage, itemsToSurface }) {
    // Before animation, make sure the z-indexes are what we expect
    [
      ...itemsToCycle.slice().reverse(),
      ...itemsToSurface.slice().reverse(),
      nextPage,
    ].forEach((element, index) => element.style.setProperty("z-index", index));

    nextPage.style.setProperty("position", "absolute");
    const lastItem = getLastItem(itemsToCycle);
    let translateDirection;
    let translateAmount;
    if (app.state.breakpoint === "sm" || app.state.breakpoint === 'md') {
      translateDirection = "Y";
      translateAmount = lastItem.offsetTop + lastItem.offsetHeight;
    } else {
      translateDirection = "X";
      translateAmount =
        lastItem.parentElement.offsetWidth -
        lastItem.offsetLeft -
        lastItem.offsetWidth;
    }
    return Promise.all(
      itemsToCycle.map((item) =>
        animateProperty({
          element: item,
          property: "transform",
          value: `translate${translateDirection}(${translateAmount}px)`,
        })
      )
    );
  }

  settle({ newOrder, nextPage, pageToCycle }) {
    const { body } = this.elements;
    pageToCycle.parentElement.removeChild(pageToCycle);
    newOrder.forEach((item, index, list) => {
      body.appendChild(item);
      item.style.removeProperty("transform");
      item.style.removeProperty("transition");
      item.style.setProperty("z-index", list.length - index);
    });
    nextPage.style.setProperty("position", "relative");
    nextPage.style.removeProperty("transform");
    nextPage.style.removeProperty("transition");
    nextPage.style.removeProperty("z-index");
    nextPage.style.setProperty("z-index", "4");
    nextPage.style.removeProperty("opacity");
    nextPage.style.removeProperty("transform");
    nextPage.style.removeProperty("top");
    nextPage.style.removeProperty("left");
    nextPage.style.removeProperty("width");
    nextPage.style.removeProperty("height");
    nextPage.style.removeProperty("z-index");
    nextPage.style.removeProperty("opacity");
    this.refreshItems();
  }

  finishPageLoad({ nextPage, index}) {
    // Dispatch a global event so other modules can tie-into it
    app.emit("main-nav:load", {
      target: nextPage,
    });

    // Do not allow for hover interaction again until the user mouses out from the nav
    const handler = (e) => {
      this.unsuspend();
      this.elements.root.removeEventListener("mouseout", handler);
    };
    this.elements.root.addEventListener("mouseout", handler);
  }
}

export const mainNav = {
  current: null,
  init: () => {
    app.addEventListener("page-load", (e) => {
      const root = e.target.querySelector(".js-main-nav");

      if (!root) {
        return;
      }

      mainNav.current = new MainNav({ root });
    });

    app.addEventListener("turbolinks:before-visit", () => {
      if (!mainNav.current) {
        return;
      }

      mainNav.current.destroy();
    });
  },
};
