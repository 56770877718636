import Component from "../component";
import app from "../icasf_app";
import * as util from "../utilities";

class MiniCalendar extends Component {
  setUpElements() {
    super.setUpElements();

    const { element } = this.props
    this.elements.previous = element.querySelector('.js-mini-calendar__previous');
    this.elements.next = element.querySelector('.js-mini-calendar__next');
    this.elements.heading = element.querySelector('.js-mini-calendar__heading');
    this.elements.body = element.querySelector('.js-mini-calendar__body');
  }

  setUpEvents() {
    this.elements.previous.onclick = this.handleButtonClick.bind(this);
    this.elements.next.onclick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(e) {
    const { monthCacheKey, url } = e.currentTarget.dataset;
    this.changeMonth(monthCacheKey, url);
  }

  changeMonth(monthCacheKey, url) {
    if (this.state.cache[monthCacheKey]) {
      return this.update({
        body: this.state.cache[monthCacheKey]
      })
    }

    const self = this;

    return util.fetchJson(url).then((data) => {
      // const updatedCache = {
      //   ...self.state.cache,
      // };
      // updatedCache[data.month_cache_key] = data.body;
      return self.update({
        body: data.body,
        // cache: updatedCache,
        monthCacheKey: data.month_cache_key,
        heading: data.heading,
        nextMonthCacheKey: data.next_month_cache_key,
        nextMonthPath: data.next_month_path,
        previousMonthCacheKey: data.previous_month_cache_key,
        previousMonthPath: data.previous_month_path,
      })
    })
  }

  render(update, previousState) {
    if (update.hasOwnProperty("nextMonthCacheKey")) {
      this.elements.next.dataset.monthCacheKey = this.state.nextMonthCacheKey;
    }
    if (update.hasOwnProperty("nextMonthPath")) {
      this.elements.next.dataset.url = this.state.nextMonthPath;
    }
    if (update.hasOwnProperty("previousMonthCacheKey")) {
      this.elements.previous.dataset.monthCacheKey = this.state.previousMonthCacheKey;
    }
    if (update.hasOwnProperty("previousMonthPath")) {
      this.elements.previous.dataset.url = this.state.previousMonthPath;
    }
    if (update.hasOwnProperty("heading")) {
      this.elements.heading.textContent = this.state.heading;
    }
    if (update.hasOwnProperty("body")) {
      this.elements.body.innerHTML = this.state.body;
    }
  }

  get defaultState() {
    const { body, heading, previous, next } = this.elements
    const { monthCacheKey } = body.dataset
    const cache = {};
    // cache[monthCacheKey] = body.innerHTML;
    return {
      body: body.innerHTML,
      heading: heading.textContent,
      nextMonthCacheKey: next.dataset.monthCacheKey,
      nextMonthPath: next.dataset.url,
      previousMonthCacheKey: previous.dataset.monthCacheKey,
      previousMonthPath: previous.dataset.url,
      cache,
      monthCacheKey,
    }
  }
}

export const miniCalendar = {
  current: null,
  init: () => {
    app.addEventListener('modal:load', (e) => {
      const element = e.target.querySelector('.js-mini-calendar');
      if (!element) {
        return
      }

      miniCalendar.current = new MiniCalendar({ element });
    });
  }
}