import app from "../icasf_app";
import Component from "../component";
import Plyr from "plyr";

export class Video extends Component {
  static current = [];
  static eventNamespace = "video";

  get defaultState() {
    return {
      paused: !this.props.autoplay,
      poster: !this.props.autoplay,
    };
  }

  get aspectRatio() {
    if (this.props.aspectRatio) {
      // Ideally, look for an aspect ratio passed in via props
      return this.props.aspectRatio;
    } else if (this.elements.iframeTarget?.dataset?.plyrAspectRatio) {
      // Otherwise, locate a data attribute `data-plyr-aspect-ratio`
      return this.elements.iframeTarget.dataset.plyrAspectRatio;
    } else if (this.elements.iframe) {
      const computedStyle = getComputedStyle(this.elements.iframe);
      // Otherwise, look for an aspect ratio applied on the video already
      if (computedStyle["aspect-ratio"]) {
        return computedStyle["aspect-ratio"].replace(" / ", ":");
      } else if (this.elements.iframe.hasAttributes("width", "height")) {
        return `${this.elements.iframe.getAttribute(
          "width"
        )}:${this.elements.iframe.getAttribute("height")}`;
      }
    }
  }

  // Destructuring here allows us to set some default arguments
  constructor({
    onReady = () => {},
    onPlay = () => {},
    onPause = () => {},
    onEnded = () => {},
    autoplay = false,
    muted = false,
    ...props
  }) {
    // Arguments are recombined into an object so that they can all be accessed via this.props
    super({ onReady, onPlay, onPause, onEnded, autoplay, muted, ...props });

    this.setUpPlayer();
    this.trackPlayerState();
    window.video = this;
  }

  setUpElements() {
    super.setUpElements();

    const { element } = this.props;
    this.elements.iframeTarget = element.querySelector(
      ".js-video__iframe-target"
    );
    this.elements.iframe = element.querySelector("iframe");

    // The easiest way to instantiate Plyr is with an iframe. Otherwise you have to construct a source object.
    // Plus, this way you get some sort progressive enhancement: if JS fails to load, they at least have an iframe
    // they can interact with.
    if (!this.elements.iframe) {
      throw new Error(
        "You must have an iframe on the page to instantiate Video."
      );
    }

    this.elements.poster = element.querySelector(".js-video__poster");
    this.elements.playPause = element.querySelector(".js-video__play-pause");
    this.elements.play = element.querySelector(".js-video__play");
    this.elements.pause = element.querySelector(".js-video__pause");
  }

  setUpEvents() {
    super.setUpEvents();

    this.elements.playPause.onclick = this.togglePlayPause.bind(this);
    if (this.elements.poster) {
      this.elements.poster.onclick = this.play.bind(this);
    }
  }

  setUpPlayer() {
    this.player = new Plyr(this.elements.iframeTarget, {
      controls: [],
      autoplay: this.props.autoplay,
      muted: this.props.muted,
      ratio: this.aspectRatio
    });
    this.player.on("ready", this.props.onReady);
    this.player.on("play", this.props.onPlay);
    this.player.on("pause", this.props.onPause);
    this.player.on("ended", this.props.onEnded);
  }

  trackPlayerState() {
    this.player.on("play", () => {
      this.update({ paused: false, poster: false })
    });
    this.player.on("pause", () => {
      this.update({ paused: true })
    });
  }

  togglePlayPause() {
    if (this.state.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  // Dispatch events to the Plyr instance
  on(event, handler) {
    this.player.on(event, handler);
  }

  play() {
    const update = {};
    update.playing = true;
    if (this.state.poster) {
      update.poster = false;
    }
    this.update(update);
    this.player.play();
  }

  pause() {
    this.update({
      paused: true,
    });
    this.player.pause();
  }

  render(update, previousState) {
    if (update.hasOwnProperty("paused") && this.state.paused !== previousState.paused) {
      if (this.state.paused) {
        this.elements.play.style.setProperty("display", "block");
        this.elements.pause.style.setProperty("display", "none");
      } else {
        this.elements.play.style.setProperty("display", "none");
        this.elements.pause.style.setProperty("display", "block");
      }
    }

    if (update.hasOwnProperty("poster") && this.elements.poster && this.state.poster !== previousState.poster) {
      if (this.state.poster) {
        this.elements.poster.style.setProperty("display", "block");
      } else {
        this.elements.poster.style.setProperty("display", "none");
      }
    }
  }
}
