/* JavaScript for BillboardModule */
import app from "../icasf_app";
import Component from "../component";
import * as util from "../utilities";
import { animateProperty } from "../utilities/animation";

const DELAY = 3000; // ms

// Export the class itself
export class BillboardModule extends Component {
  static useMobileLayout = () => {
    return app.state.breakpoint === "sm" || app.state.breakpoint === "md";
  };

  constructor(props) {
    super(props);

    if (!this.elements.overlay) {
      return;
    }

    // Set up the necessary prerequisites for the billboard animation
    this.prestage();

    // Group all the steps of the billboard animation in a single promise.
    // This allows us to easily have a failsafe in case something goes wrong.
    const animation = () => {
      return new Promise((resolve, reject) => {
        const primaryDelay = util.wait(DELAY);

        let imageLoaded = false;
        const imageLoadDelay = this.waitForImageLoad().then(() => {
          imageLoaded = true;
          return true;
        });

        // If the user clicks the billboard, they probably want it to go away.
        // If the image is loaded, we have enough information to continue the animation.
        // Otherwise we need to bail out and skip the rest.
        // TODO: this handler needs to be removed during the `stage` method.
        this.elements.overlay.addEventListener("click", () => {
          if (imageLoaded) {
            resolve();
          } else {
            reject();
          }
        });

        // If the user doesn't click the billboard, then they are just patiently waiting.
        // Once the image is loaded and they've waited through the delay,
        // then continue with the animation.
        return Promise.all([primaryDelay, imageLoadDelay])
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      })
        .then(() => {
          // Set up the first frame of the animation
          return this.stage();
        })
        .then(() => {
          // Trigger reflow so that the browser paints the new styles before continuing.
          this.elements.image.offsetWidth;

          // Proceed to play the animation
          return this.collapse();
        });
    };

    util
      .promiseOrTimeout({
        promise: animation,
        timeout: 5000,
        result: "reject",
      })
      .catch(() => {
        return this.handleFailure();
      })
      .finally(() => {
        return this.settle();
      });
  }

  setUpElements() {
    super.setUpElements();

    const { element } = this.props;
    this.elements.element = element;
    this.elements.overlay = document.getElementById("billboard-overlay");
    this.elements.overlayImage = BillboardModule.useMobileLayout()
      ? this.elements.overlay.querySelector(
          ".js-billboard__vertical-overlay-image"
        )
      : this.elements.overlay.querySelector(
          ".js-billboard__horizontal-overlay-image"
        );
    this.elements.sidebar = this.elements.overlay.querySelector(
      ".js-billboard__overlay-sidebar"
    );
    this.elements.image = element.querySelector(".js-billboard__image");
    this.elements.siteNav = document.getElementById("main-nav");
  }

  waitForImageLoad() {
    return new Promise((resolve, reject) => {
      if (this.elements.overlayImage.complete) {
        resolve();
      } else {
        this.elements.overlayImage.addEventListener('load', resolve);
        this.elements.overlayImage.addEventListener('error', reject);
      }
    });
  }

  // When the page loads, scroll to the top and disable scrolling
  prestage() {
    window.scrollTo(0, 0);
    document.documentElement.style.setProperty('overflow', 'hidden');
  }

  // On desktop, once the image is loaded, scale it so that it matches the billboard image.
  // On mobile, there isn't any scaling animation, so we can just bypass this function.
  stage() {
    this.elements.overlay.style.setProperty("background-color", 'transparent');
    if (BillboardModule.useMobileLayout()) {
      return true;
    }

    const { overlayImage, image, siteNav } = this.elements;
    const overlayRect = overlayImage.getBoundingClientRect();
    const imageRect = image.getBoundingClientRect();
    const imgRatio = imageRect.width / imageRect.height;
    const windowRatio = window.innerWidth / window.innerHeight;
    const overlayRatio = overlayRect.width / overlayRect.height;
    let scale;
    // If image is "more landscape" than the window is, then the image should be
    // scaled up so that its height matches the window height. This will cause
    // the image to be wider than the viewport, and it will clipped by the viewport's
    // left edge. That should match the overlay image's `object-fit: cover; object-position: top right;`
    if (imgRatio > overlayRatio) {
      scale = window.innerHeight / imageRect.height;
    } else {
      // If the image "more portrait" than the window is, the image should be
      // scale up so that its width matches the overlay's width. In this condition,
      // we know that the overlay image is not auto-cropped horizontally, so all
      // we have to to do is size the image so that the widths match.
      scale = overlayRect.width / imageRect.width;
    }

    image.style.setProperty("transition", "transform 0s");
    image.style.setProperty(
      "transform",
      `translateX(${overlayRect.right - imageRect.right}px) translateY(-${
        imageRect.top
      }px) scale(${scale})`
    );
    image.style.setProperty("z-index", "999");

    siteNav.style.setProperty("transform", "translateX(100%)");
  }

  collapse() {
    const { overlay, overlayImage, image, sidebar, siteNav } = this.elements;
    if (BillboardModule.useMobileLayout()) {
      return overlay.animate([{ opacity: 0 }], {
        duration: 500,
        easing: "ease-in-out",
        fill: "forwards",
      }).finished;
    } else {
      overlayImage.style.setProperty("visibility", "hidden");
      return Promise.all([
        image.animate([{ transform: 'translateY(0) scale(1)'}], { duration: 1000, easing: "ease-in-out", fill: 'forwards' }).finished,
        sidebar.animate([{ opacity: 0 }], { duration: 500, delay: 500, fill: 'forwards' }).finished,
        siteNav.animate([{ transform: 'translateX(0)' }], { duration: 1000, delay: 500, fill: 'forwards' }).finished,
      ])
    }
  }

  handleFailure() {
    const { overlayImage, image, siteNav } = this.elements;
    if (BillboardModule.useMobileLayout()) {
      return true;
    } else {
      overlayImage.style.setProperty("visibility", "hidden");
      image.style.removeProperty("transform");
      image.style.removeProperty("transition");
      siteNav.style.removeProperty("transform");
      siteNav.style.removeProperty("transition");
      return true;
    }
  }

  settle() {
    const { overlay, image, siteNav } = this.elements;
    overlay.style.setProperty("display", "none");
    image.style.removeProperty("z-index");
    document.documentElement.style.removeProperty('overflow');
  }
}

export const billboardModules = {
  current: [],
  init: () => {
    document.addEventListener("DOMContentLoaded", (e) => {
      console.log("dom content loaded... init billboards");
      if (util.isEconomyEditMode()) {
        return;
      }

      const elements = e.target.querySelectorAll(".js-billboard-module");

      if (!Boolean(elements.length)) {
        return;
      }

      const instances = [...elements].map(
        (element) => new BillboardModule({ element })
      );
      billboardModules.current = [...billboardModules.current, ...instances];
    });
  },
};
