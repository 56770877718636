/* JavaScript for ImageGridModule */
import app from "../icasf_app";
import { lightbox } from "../components/lightbox";
import Component from "../component";

// Export the class itself
export class ImageGridModule extends Component {
  constructor({ element }) {
    super({ element });

    console.log("image grid", this)

    const images = element.querySelectorAll(".js-image-grid__image");
    const lightboxSlides = element.querySelectorAll(".js-lightbox-slide");

    [...images].forEach((image, index) => {
      image.addEventListener("click", () => {
        lightbox.current.open(lightboxSlides, index);
      });
    });
  }
}

// Exports an array of all the current instances
export const imageGridModules = {
  current: [],
  init: () => {

    app.addEventListener("page-load", (e) => {
      // Initialize any instances of the ImageGridModule on any given page
      const elements = e.target.querySelectorAll(".js-image-grid-module");
  
      if (!Boolean(elements.length)) {
        return;
      }
  
      const instances = [...elements].map(
        (element) => new ImageGridModule({ element })
      );
      
      if (!Boolean(instances.length)) {
        return;
      }

      imageGridModules.current = [...imageGridModules.current, ...instances];
    })
  }
};
