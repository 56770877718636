/* JavaScript for PosterModule */

import app from "../icasf_app";
import Component from "../component";
import { Video } from "../elements";

// Export the class itself
export class PosterModule extends Component {
  get defaultState() {
    return {
      paused: true,
    };
  }

  constructor(props) {
    super(props);
    this.setUpVideo();
  }

  setUpElements() {
    super.setUpElements();
    const { element } = this.props;
    this.elements.video = element.querySelector(".js-video");
    this.elements.headline = element.querySelector(".js-poster-module__headline");
    this.elements.cta = element.querySelector(".js-poster-module__cta");
  }

  setUpVideo() {
    this.video = new Video({
      element: this.elements.video,
      onPlay: () => {
        this.update({
          paused: false,
        });
      },
      onPause: () => {
        this.update({
          paused: true,
        });
      },
      onEnded: () => {
        this.update({
          paused: true,
        });
      }
    });
  }

  render(update) {
    const { headline, cta } = this.elements;
    if (update.hasOwnProperty("paused")) {
      if (this.state.paused) {
        [headline, cta].forEach((element) => {
          element.animate({ opacity: 1 }, { duration: 200, fill: "forwards" });
        });
      } else {
        [headline, cta].forEach((element) => {
          element.animate({ opacity: 0 }, { duration: 200, fill: "forwards" });
        });
      }
    }
  }
}

export const posterModules = {
  current: [],
  init: () => {
    app.addEventListener("page-load", (e) => {
      const elements = e.target.querySelectorAll(
        ".js-poster-module--with-video"
      );

      if (!Boolean(elements.length)) {
        return;
      }

      const instances = [...elements].map(
        (element) => new PosterModule({ element })
      );
      posterModules.current = [...posterModules.current, ...instances];
    });
  },
};
