import app from "../icasf_app";
import Component from "../component";
import * as util from "../utilities";
import { data } from "flickity";

export class RemoteContentArea extends Component {
  static eventNamespace = "remote-content-area";

  constructor(props) {
    super(props);

    this.emit("initialize");
  }

  setUpElements() {
    super.setUpElements();

    const { element } = this.props;
    this.elements.element = element;
    this.elements.target = element.querySelector('.js-remote-content-area__target');
    this.elements.loadMoreButton = element.querySelector('.js-remote-content-area__load-more');
  }

  setUpEvents() {
    super.setUpEvents();

    if (this.elements.loadMoreButton) {
      this.elements.loadMoreButton.onclick = this.handleLoadMore.bind(this);
    }
  }

  fetchAndRender(url, action, mode = "replace") {
    // Some controllers need to respond to js requests with the full page,
    // not just the paginated content. To help the controller know
    // which request wants what, we set a URL param.
    const formattedUrl = util.editSearchParams(url, (params) => {
      params.set("remote", action);
    });
    return util.fetchJson(formattedUrl).then((data) => {
      // Create a document fragment to minimize the number of reflows needed
      let fragment = document.createRange().createContextualFragment(data.body);

      // Pull out the update `next_page` link, then update/remove the "Load More" button
      this.updateLoadMoreButton(data.pagination_url);

      // At this point, the raw html has been parsed into real nodes.
      // When we append this document fragment to the DOM, the fragment
      // will be emptied out. Therefore, we save a reference to the nodes
      // now, so that we can access them even after they're appended.
      const content = [...fragment.children];

      // Dispatch an event in case any end-implementation needs to massage
      // the results further before they're added to the page
      this.emit("before-render", { content });

      // Certain renders are intended to fully replace the target's content
      if (mode === "replace") {
        this.elements.target.innerHTML = "";
      }

      // Finally, add the fetched content to the page
      this.elements.target.appendChild(fragment);

      // Dispatch an event in case any end-implementation needs to tie-in
      this.emit("load", { content, fragment });

      // Update the current URL
      if (mode === "replace") {
        history.replaceState(history.state, null, url);
      }
    });
  }

  handleLoadMore() {
    const { paginationUrl } = this.elements.loadMoreButton.dataset;
    return this.fetchAndRender(paginationUrl, "pagination", "append");
  }

  updateLoadMoreButton(paginationUrl) {
    if (!this.elements.loadMoreButton) {
      return fragment;
    }

    if (paginationUrl) {
      this.elements.loadMoreButton.dataset.paginationUrl = paginationUrl;
    } else {
      this.elements.loadMoreButton.style.setProperty('display', 'none');
    }

    return true;
  }

  get defaultEventData() {
    return {
      target: this.elements.target,
      remoteContentArea: this
    };
  }
}

export const remoteContentAreas = {
  current: [],
  init: () => {
    app.addEventListener('page-load', (e) => {
      const elements = e.target.querySelectorAll('.js-remote-content-area');

      if (!Boolean(elements.length)) {
        return;
      }

      const instances = [...elements].map((element) => {
        return new RemoteContentArea({ element });
      });

      remoteContentAreas.current = [
        ...remoteContentAreas.current,
        ...instances
      ];
    })
  }
};
