/* JavaScript for CarouselModule */

import app from "../icasf_app";
import Component from "../component";
import { CrossfadeCarousel } from "../elements";

// Export the class itself
export class CarouselModule extends Component {

  constructor(props) {
    super(props);

    this.imageCarousel = new CrossfadeCarousel(this.elements.element, {}, {
      slideWrapper: this.elements.imageCarousel,
      slides: this.elements.imageSlides,
      nextControl: this.elements.nextButton,
      previousControl: this.elements.previousButton
    });

    this.captionCarousel = new CrossfadeCarousel(this.elements.element, {
      accessibility: false
    }, {
      slideWrapper: this.elements.captionCarousel,
      slides: this.elements.captionSlides,
    });

    this.imageCarousel.on('change', ({ index }) => {
      this.captionCarousel.select(index);
    });

    window.carousel = this;
    // this.imageCarousel.select(1);
  }

  setUpElements() {
    super.setUpElements();

    const { element } = this.props;
    this.elements.element = element;
    this.elements.imageCarousel = element.querySelector('.js-carousel-module__images');
    this.elements.captionCarousel = element.querySelector('.js-carousel-module__captions');
    this.elements.nextButton = element.querySelector('.js-carousel-module__next');
    this.elements.previousButton = element.querySelector('.js-carousel-module__previous');
    this.elements.imageSlides = this.elements.imageCarousel.querySelectorAll('.js-carousel-module__slide');
    this.elements.captionSlides = this.elements.captionCarousel.querySelectorAll('.js-carousel-module__slide');
  }


}

export const carouselModules = {
  current: [],
  init: () => {
    app.addEventListener("page-load", (e) => {
      const elements = e.target.querySelectorAll(".js-carousel-module");

      if (!Boolean(elements.length)) {
        return;
      }

      const instances = [...elements].map(
        (element) => new CarouselModule({ element })
      );
      carouselModules.current = [...carouselModules.current, ...instances];
    });
  },
};
