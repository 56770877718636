import app from "../icasf_app";
import * as util from "../utilities";

/*
  This component helps with accessibility when we need to make an
  entire block of content a link.

  Generally speaking, we don't want to wrap entire blocks of content
  in an <a> tag. If you do, a screen reader will announce the whole
  block at once. 

  Instead, we wrap a meaninful span of tag in the <a> and use this
  JS to add a "click" handler. 

  To use, apply the `.js-block-level-link` to the element you want
  to be clickable. This element should contain an <a> tag.

    <div class="js-block-level-link">
      <!-- other stuff here -->
      <a href="/home">Go to the homepage</a>
      <!-- other stuff here -->
    </div>

  NOTE: we don't use a click handler because we want to preserve
  the ability to select text. For more on this approach, see this
  article from Inclusive Components: https://inclusive-components.design/cards/

  NOTE: This component takes special precautions to avoid redundant
  initialization when content is loaded asynchronously. This helps keep
  things clean when "load more" buttons are clicked. Theoretically all
  components should take these precautions, but it's not exactly easy
  to make a one-size-fits-all solution.
*/

const SELECTOR = ".js-block-level-link";
export class BlockLevelLink {
  constructor(element) {
    const alreadyInitialized = blockLevelLinks.current.some(
      (instance) => instance.element === element
    );
    if (alreadyInitialized) {
      return;
    }

    this.element = element;

    // Prefer to target an element directly labeled as the direct, in case there are other links that are children.
    const anchorTag =
      element.querySelector(".js-block-level-link__target") ||
      element.querySelector("a[href]");

    if (!anchorTag) {
      return;
    }

    let mouseDownTime;

    element.addEventListener("mousedown", () => {
      mouseDownTime = new Date().getTime();
    });

    element.addEventListener("mouseup", () => {
      if (!mouseDownTime) {
        return;
      }

      const currentTime = new Date().getTime();
      if (currentTime - mouseDownTime < 200) {
        anchorTag.click();
      }

      mouseDownTime = null;
    });
  }
}

export const blockLevelLinks = {
  current: [],

  init: () => {
    app.addEventListener("page-load", (e) => {
      if (util.isEconomyEditMode()) {
        return;
      }

      if (e.target.matches(SELECTOR)) {
        const instance = new BlockLevelLink(e.target);
        blockLevelLinks.current = [...blockLevelLinks.current, instance];
      } else {
        const elements = e.target.querySelectorAll(SELECTOR);
        const instances = [...elements]
          .map((element) => {
            return new BlockLevelLink(element);
          })
          .filter((b) => b);
  
        blockLevelLinks.current = [...blockLevelLinks.current, ...instances];
      }
    });
  },
};
