/* JavaScript for AccordionModule */
import app from "../icasf_app";
import Component from "../component";
export class Accordion extends Component {
  get defaultState() {
    return {
      open: this.props.initiallyOpen,
    };
  }

  setUpElements() {
    super.setUpElements();

    const { element } = this.props;
    this.elements.element = element;
    this.elements.toggler = element.querySelector(".js-accordion__toggler");
    this.elements.body = element.querySelector(".js-accordion__body");
    this.elements.openIcon = element.querySelector(".js-accordion__open-icon");
    this.elements.closeIcon = element.querySelector(
      ".js-accordion__close-icon"
    );
  }

  setUpEvents() {
    this.elements.toggler.onchange = this.handleToggle.bind(this);
  }

  handleToggle(e) {
    console.log('Toggle', e.target.checked)
    if (e.target.checked === true) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.update({ open: true });
  }

  close() {
    this.update({ open: false });
  }

  toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  render(update) {
    if (update.hasOwnProperty("open")) {
      if (this.state.open) {
        this.elements.body.style.setProperty("display", "block");
        this.elements.openIcon.style.setProperty("display", "none");
        this.elements.closeIcon.style.setProperty("display", "block");
        this.elements.toggler.setAttribute("checked", "true");
      } else {
        this.elements.body.style.setProperty("display", "none");
        this.elements.openIcon.style.setProperty("display", "block");
        this.elements.closeIcon.style.setProperty("display", "none");
        this.elements.toggler.removeAttribute("checked");
      }
    }
  }
}

// Exports an array of all the current instances
export const accordions = {
  current: [],
};

// Export an init function that looks for and instantiates the module on page-load
export const init = () => {
  // Initialize any instances of the AccordionModule on any given page
  app.addEventListener("page-load", (e) => {
    accordions.current = [...e.target.querySelectorAll(".js-accordion")].map(
      (element) => {
        const initiallyOpen = element.classList.contains(".js-accordion--open");
        new Accordion({ element, initiallyOpen });
      }
    );
  });
};
