/* JavaScript for VideoModule */

import app from "../icasf_app";
import Component from "../component";
import { Video } from "../elements";

// Export the class itself
export class VideoModule extends Component {
  constructor(props) {
    super(props)

    this.setUpVideo();
  }

  setUpElements() {
    super.setUpElements();
    const { element } = this.props;
    this.elements.video = element.querySelector(".js-video");
  }

  setUpVideo() {
    this.video = new Video({
      element: this.elements.video
    })
  }
}

export const videoModules = {
  current: [],
  init: () => {
    app.addEventListener("page-load", (e) => {
      const elements = e.target.querySelectorAll(
        ".js-video-module"
      );

      if (!Boolean(elements.length)) {
        return;
      }

      const instances = [...elements].map(
        (element) => new VideoModule({ element })
      );
      videoModules.current = [...videoModules.current, ...instances];
    });
  },
}