import app from "../icasf_app";
import Component from "../component";
import { slugify } from "../utilities";

export class JumpLinkEditor extends Component {
  get defaultState() {
    return { enabled: false, slug: null };
  }

  constructor(props) {
    super(props);

    this.setSlug();
  }

  setUpElements() {
    super.setUpElements();
    const { element } = this.props;
    this.elements.checkbox = element.querySelector(".magic_module_jump_link input.boolean");
    this.elements.textInput = element.querySelector(".magic_module_title input, .magic_module_text input");
    this.elements.slugInput = element.querySelector(".magic_module_jump_link_slug input");
    this.elements.hint = element.querySelector(".js-jump-link-hint");
  }

  setUpEvents() {
    super.setUpEvents();

    const handler = this.setSlug.bind(this);
    this.elements.checkbox.onchange = handler;
    this.elements.textInput.onkeyup = handler;
    this.elements.slugInput.onkeyup = handler;
  }

  setSlug() {
    if (!this.elements.checkbox.checked) {
      this.update({
        slug: ""
      })
      return;
    } else if (this.elements.slugInput.value) {
      this.update({
        slug: slugify(this.elements.slugInput.value)
      })
    } else if (this.elements.textInput.value) {
      this.update({
        slug: slugify(this.elements.textInput.value)
      })
    } else {
      this.update({
        slug: ""
      })
    }
  }

  render(update) {
    if (this.state.slug) {
      const url = new URL(window.location.href);
      url.pathname = url.pathname.replace("/edit", "").replace("/new", "");
      url.hash = this.state.slug;
      this.elements.hint.innerHTML = `Your jump link with be <a href="${url.href}">${url.pathname + url.hash}</a>`;
    } else {
      this.elements.hint.textContent = "";
    }
  }
}

export const jumpLinkEditors = {
  current: [],
  init: () => {
    app.addEventListener("economy:magic_module_edit", (e) => {
      const element = e.target.querySelector(".magic_module_jump_link");

      if (!element) {
        return;
      }

      jumpLinkEditors.current = [
        ...jumpLinkEditors.current,
        new JumpLinkEditor({ element: e.target }),
      ];
    });
  },
};
