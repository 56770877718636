import initAccordionEdit from "./components/accordion_end";
import * as accordion from "./components/accordion";
import { blockLevelLinks } from "./components/block_level_link";
import { jumpLinkEditors } from "./components/jump_link_editor";
import { lightbox } from "./components/lightbox";
import { mainNav } from "./components/main_nav";
import { miniCalendar } from "./components/mini_calendar";
import { modal } from "./components/modal";
import { remoteContentAreas } from "./components/remote_content_area";
import { responsiveImages } from "./components/responsive_image";

// BEGIN MAGIC_MODULE_IMPORT
import { billboardModules } from './magic_modules/billboard_module';
import { carouselModules } from "./magic_modules/carousel_module";
import { imageGridModules } from './magic_modules/image_grid_module';
import { posterModules } from "./magic_modules/poster_module";
import { videoModules } from './magic_modules/video_module';
// END MAGIC_MODULE_IMPORT

// BEGIN MAGIC_MODULE_INIT
billboardModules.init();
carouselModules.init();
imageGridModules.init();
posterModules.init();
videoModules.init();
// END MAGIC_MODULE_INIT

accordion.init();
initAccordionEdit();
blockLevelLinks.init();
jumpLinkEditors.init();
lightbox.init();
mainNav.init();
miniCalendar.init();
modal.init();
remoteContentAreas.init();
responsiveImages.init();
